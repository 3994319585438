import { TECollapse } from "tw-elements-react"
import React, { useEffect, useState } from "react"

import { CreateType } from "./CreateType"
import { ASSETS } from "../../api/Registry"
import { API_RouteReplace, classNames } from "../../utils/Helpers"
import HttpServices from "../../services/HttpServices"
import { Loading } from "../../components/utils/Loading"

export const AssetTypesListing = ({ category, configureAssetType, activeType }) => {
    const [state, setstate] = useState({
        httpStatus: 200,
        status: 'pending',
        data: {
            types: null,
        },
        show: {
            createType: false
        }
    })

    const [newAssetType, setNewAssetType] = React.useState(null)

    const fetchAssetTypes = async () => {
        let { status } = state
        let { httpStatus } = state

        let types = null

        try {
            let response: any = null
            let assetTypesRoute = null

            assetTypesRoute = API_RouteReplace(ASSETS.CATEGORY_TYPES, ':category', category.uuid)
            response = await HttpServices.httpGet(assetTypesRoute)

            httpStatus = response.status

            if (httpStatus === 200) {
                const payload: any = response.data.payload

                status = 'fulfilled'
                types = payload.types
            } else {
                status = 'rejected'
            }
        } catch (error) {
            status = 'rejected'
            httpStatus = 500
        }

        setstate((prevState: any) => ({
            ...prevState, status,
            httpStatus,
            data: {
                ...prevState.data,
                types: types,
            },
        }));
    }

    useEffect(() => {
        fetchAssetTypes()
    }, [])

    const toggleCreateTypePanel = () => {
        setstate((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                createType: !prev.show.createType
            }
        }))
    }

    const onCreateTypeComplete = (newType: any) => {
        toggleCreateTypePanel()
        setNewAssetType(newType)
    }

    return (
        <React.Fragment>
            <TECollapse show={true} className="!mt-0 !h-auto !rounded-none !shadow-none !block">
                {
                    state.status === 'rejected' ? (
                        <>

                        </>
                    ) : state.status === 'fulfilled' ? (
                        <div className="pb-4">
                            <div className="flex flex-col px-3 text-sm pt-2">
                                <p className="text-sm md:mb-2 text-gray-700 text-wrap">
                                    {category.description}
                                </p>

                                <div className="flex flex-row-reverse w-full pr-2">
                                    <button type="button" onClick={toggleCreateTypePanel} className="flex flex-row items-center gap-x-3 align-middle py-1.5 text-sm text-primary hover:text-secondary">
                                        <i className="fad fa-plus fa-lg"></i>
                                        Create Asset Type
                                    </button>
                                </div>

                                {
                                    state.data.types.map((type: any) => {
                                        return (
                                            <React.Fragment>
                                                <button type="button" onClick={() => configureAssetType(type)} className={
                                                    classNames(
                                                        activeType && activeType.uuid === type.uuid ? 'bg-light text-primary hover:text-secondary rounded-md' : 'hover:bg-gray-100 text-gray-600 hover:text-gray-800',
                                                        "px-2 flex flex-row text-start items-center gap-x-3 py-3 border-b last:border-b-0 hover:rounded-md"
                                                    )
                                                } key={type.uuid}>
                                                    <span>-</span>
                                                    <span>{type.name}</span>
                                                </button>
                                            </React.Fragment>
                                        )
                                    })
                                }

                                {
                                    newAssetType && (
                                        <React.Fragment>
                                            <button type="button" onClick={() => configureAssetType(newAssetType)} className={
                                                classNames(
                                                    activeType && activeType.uuid === newAssetType.uuid ? 'bg-light text-primary hover:text-secondary rounded-md' : 'hover:bg-gray-100 text-gray-600 hover:text-gray-800',
                                                    "px-2 flex flex-row align-middle items-center gap-x-3 py-3 border-b last:border-b-0 hover:rounded-md"
                                                )
                                            } key={newAssetType.uuid}>
                                                <span>-</span>
                                                <span>{newAssetType.name}</span>
                                            </button>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="py-4">
                            <Loading />
                        </div>
                    )
                }
            </TECollapse>

            <CreateType
                category={category}
                show={state.show.createType}
                onComplete={onCreateTypeComplete}
                showOrHide={toggleCreateTypePanel}
            />
        </React.Fragment>
    )
}