import { toast } from "react-toastify"
import React, { useEffect, useState } from "react"
import { ListboxButton, ListboxOption, MenuButton, MenuItem } from "@headlessui/react"

import { ASSETS } from "../../api/Registry"
import { ListBox } from "../../lib/fragments/ListBox"
import HttpServices from "../../services/HttpServices"
import { API_RouteReplace, generateUniqueKey } from "../../utils/Helpers"
import { InputBasic } from "../../components/utils/InputBasic"
import { TwoToneModal } from "../../lib/fragments/TwoToneModal"
import { ToggleSwitch } from "../../components/utils/ToggleSwitch"
import { G_onInputBlurHandler, G_onInputChangeHandler } from "../../components/utils/InputHandlers"
import { APPLICATION } from "../../utils/Constants"
import DropDown from "../../lib/fragments/DropDown"
import { AssetDataType } from "./AssetDataType"

export const AddAttribute = ({ show, showOrHide, category, type, onComplete }) => {
    const [state, setstate] = useState({
        posting: false,
        httpStatus: 200,
        status: 'pending',
        data: {
            groups: null,
            attributes: null
        },
        input: {
            group: '',
            label_name: '',
            attribute: '',
            data_type: '',
            linked: '',
            required: false,
        },
        errors: {
            group: '',
            label_name: '',
            attribute: '',
            data_type: '',
            linked: '',
            required: '',
        }
    })

    const [activeGroup, setActiveGroup] = useState(null)
    const [activeAttribute, setActiveAttribute] = useState(null)
    const [groupAttributes, setGroupAttributes] = useState(null)

    const [attrCount, setAttrCount] = useState(1)
    const [customForm, setCustomForm] = useState(false)
    const [attrDataType, setAttrDataType] = useState(null)

    const dataTypeArray = [
        { key: "freetext", value: "Freetext" },
        { key: "number", value: "Number" },
        { key: "date", value: "Date" },
        { key: "linked", value: "Linked" },
    ]

    useEffect(() => {
        if (show) {
            const fetchAssetFormAttributes = async () => {
                setstate((prevState: any) => ({
                    ...prevState,
                    status: 'pending'
                }))

                let groups = []
                let attributes = null

                let { status } = state
                let { httpStatus } = state

                try {
                    let response: any = null
                    let attributeRoute = null

                    attributeRoute = API_RouteReplace(ASSETS.FORM_ATTRIBUTES, ':category', category.uuid)
                    attributeRoute = API_RouteReplace(attributeRoute, ':type', type.uuid)

                    response = await HttpServices.httpGet(attributeRoute)

                    httpStatus = response.status

                    if (httpStatus === 200) {
                        const payload: any = response.data.payload
                        attributes = payload.attributes

                        attributes.forEach((attribute: any) => {
                            groups.push({
                                name: attribute.group,
                                uuid: attribute.uuid,
                            })
                        });

                        setActiveAttribute(null)
                        setActiveGroup(groups[0])
                        setGroupAttributes(attributes[0].attributes)

                        setAttrCount(1)
                        setCustomForm(false)
                        status = 'fulfilled'
                    } else {
                        status = 'rejected'
                    }
                } catch (error) {
                    status = 'rejected'
                    httpStatus = 500
                }

                setstate((prevState: any) => ({
                    ...prevState, status, httpStatus,
                    data: {
                        ...prevState.data,
                        groups: groups,
                        attributes: attributes,
                    },
                }));
            }

            fetchAssetFormAttributes()
        }
    }, [show, category, type])

    const onChangeGroupsHandler = (e: any) => {
        let { data } = state
        let electusGroup = data.attributes.find(
            (obj: any) => obj.uuid === e
        )

        setActiveGroup({
            name: electusGroup.group,
            uuid: electusGroup.uuid
        })

        setGroupAttributes(electusGroup.attributes)
        setActiveAttribute(null)

        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                group: e
            }
        }))
    }

    const onChangeAttributesHandler = (e: any) => {
        let { data } = state
        let electusGroup = data.attributes.find(
            (obj: any) => obj.uuid === activeGroup.uuid
        )

        let electusAttribute = electusGroup.attributes.find(
            (obj: any) => obj.uuid === e
        )

        setActiveAttribute(electusAttribute)
        setAttrDataType(electusAttribute.data_type)

        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                attribute: e,
                required: false,
                data_type: electusAttribute.data_type
            }, errors: {
                ...prev.errors,
                attribute: '',
                data_type: '',
            }
        }))
    }

    const handleCheckboxChange = () => {
        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                required: !prev.input.required
            }
        }))
    }

    const toggleCustomAttributeForm = () => {
        let { data } = state
        let { posting } = state

        if (!posting) {
            setCustomForm(!customForm)

            setActiveAttribute(null)
            setActiveGroup(data.groups[0])
            setGroupAttributes(data.attributes[0].attributes)

            setstate((prev) => ({
                ...prev,
                input: {
                    ...prev.input,
                    linked: '',
                    attribute: '',
                    label_name: '',
                    required: false,
                    data_type: dataTypeArray[0].key
                }, errors: {
                    ...prev.errors,
                    linked: '',
                    data_type: '',
                    attribute: '',
                    label_name: '',
                }
            }))
        }
    }

    const onChangeHandler = (e: any) => {
        let { posting } = state

        if (!posting) {
            let { input } = state
            let { errors } = state
            let output: any = G_onInputChangeHandler(e)

            const updatedInput = {
                ...input,
                [e.target.name]: output.value
            };

            const updatedErrors = {
                ...errors,
                [e.target.name]: output.error
            };

            setstate((prev) => ({
                ...prev,
                input: updatedInput,
                errors: updatedErrors,
            }));
        }
    }

    const onInputBlur = (e: any) => {
        let { posting } = state

        if (!posting) {
            let { input } = state
            let { errors } = state
            let output: any = G_onInputBlurHandler(e, '')

            const updatedInput = {
                ...input,
                [e.target.name]: output.value
            };

            const updatedErrors = {
                ...errors,
                [e.target.name]: output.error
            };

            setstate((prev) => ({
                ...prev,
                input: updatedInput,
                errors: updatedErrors,
            }));
        }
    }

    const onChangeListBoxHandler = (e: any) => {
        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                data_type: e
            }
        }))
    }

    const onFormSubmitHandler = (e: any) => {
        e.preventDefault()
        let { posting } = state

        if (!posting) {
            let validity = true
            let { input } = state

            const inputArray = Object.keys(input)

            inputArray.forEach((inputObject: any) => {
                let event: any = {
                    target: {
                        name: inputObject,
                    }
                }

                switch (inputObject) {
                    case 'label_name':
                        if (customForm) {
                            let oTarget = e.target[inputObject]

                            event.target.required = oTarget.required
                            event.target.value = oTarget.value.trim()

                            onInputBlur(event)
                        }
                        break;

                    case 'attribute':
                        if (input[inputObject].trim().length < 1 && !customForm) {
                            setstate({
                                ...state,
                                errors: {
                                    ...state.errors,
                                    attribute: 'Select an attribute to add',
                                }
                            })

                            validity = false
                        }
                        break;

                    case 'data_type':
                        if (input[inputObject].trim().length < 1) {
                            setstate({
                                ...state,
                                errors: {
                                    ...state.errors,
                                    data_type: 'Select the data type for the attribute',
                                }
                            })

                            validity = false
                        }
                        break;

                    case 'linked':
                        console.log('To be reviewed');
                        break;

                    default:
                        break;
                }
            })

            let { errors } = state
            const errorArray = Object.keys(input)

            errorArray.forEach((errorObject) => {
                if (errors[errorObject].length > 0) {
                    validity = false
                }
            })

            if (validity) {
                setstate((prev) => ({
                    ...prev,
                    posting: !prev.posting
                }))

                addAttributeToAssetForm()
            }
        }
    }

    const addAttributeToAssetForm = async () => {
        let { input } = state
        let { errors } = state
        let { httpStatus } = state

        try {
            let groups = []
            let attributes = null
            let formData = new FormData()

            formData.append('attribute', input.attribute)
            formData.append('label_name', input.label_name)
            formData.append('mandatory', input.required ? 'Y' : 'N')
            formData.append('data_type', input.data_type)
            formData.append('linked', input.linked)

            let response: any = null
            let attributeRoute = null

            attributeRoute = API_RouteReplace(ASSETS.FORM_ATTRIBUTES, ':category', category.uuid)
            attributeRoute = API_RouteReplace(attributeRoute, ':type', type.uuid)

            response = await HttpServices.httpPost(attributeRoute, formData)

            httpStatus = response.status
            const payload: any = response.data.payload

            if (httpStatus === 200) {
                const addedAttr = activeAttribute
                    ? activeAttribute.label
                    : input.label_name

                const updatedInput = {
                    ...input,
                    linked: '',
                    attribute: '',
                    label_name: '',
                    required: false,
                    data_type: dataTypeArray[0].key
                };

                setstate((prev) => ({
                    ...prev,
                    input: updatedInput,
                }));

                setCustomForm(false)
                onComplete(payload.set)
                attributes = payload.rem

                if (attributes) {
                    attributes.forEach((attribute: any) => {
                        groups.push({
                            name: attribute.group,
                            uuid: attribute.uuid,
                        })
                    });

                    setActiveAttribute(null)
                    setActiveGroup(groups[0])
                    setGroupAttributes(attributes[0].attributes)

                    setstate((prevState: any) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            groups: groups,
                            attributes: attributes,
                        },
                    }));
                }

                let toastMessage = (
                    <span className="text-sm">
                        <span className="block text-primary">{addedAttr} added</span>
                        <span className="block">Add another attribute or close this window.</span>
                    </span>
                )

                if (attrCount > 2) {
                    toastMessage = (
                        <span className="text-sm">
                            <span className="block text-primary">{addedAttr} attribute added</span>
                        </span>
                    )
                }

                setAttrCount(attrCount + 1)

                toast.success(toastMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                if (response.status === 422) {
                    Object.entries(response.data).forEach(([field, errorMessages]) => {
                        const submissionErrors = {
                            ...errors,
                            [field]: errorMessages
                        };

                        setstate((prev) => ({
                            ...prev,
                            errors: submissionErrors,
                        }));
                    });
                } else if (response.status === 400) {
                    attributes = payload.rem

                    if (attributes) {
                        attributes.forEach((attribute: any) => {
                            groups.push({
                                name: attribute.group,
                                uuid: attribute.uuid,
                            })
                        });

                        setActiveAttribute(null)
                        setActiveGroup(groups[0])
                        setGroupAttributes(attributes[0].attributes)
                    }

                    toast.error(
                        <span className="text-sm">
                            <span className="block text-primary">{activeAttribute.label} already exists</span>
                            <span className="block">Add another attribute or close this window.</span>
                        </span>, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(APPLICATION.ERR_MSG, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        } catch (error) {
            httpStatus = 500
        }

        setstate((prevState) => ({
            ...prevState, httpStatus, posting: !prevState.posting
        }));
    }

    const changeAttrDataType = (attr: any) => {
        setAttrDataType(attr)

        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                data_type: attr
            }
        }))
    }

    return (
        <React.Fragment>
            <TwoToneModal
                size={"2xl"}
                show={show}
                status={state.status}
                posting={state.posting}
                showOrHide={showOrHide}
                formAction={"Add Attribute"}
                onSubmit={onFormSubmitHandler}
                header={
                    <>
                        <div className="w-full flex flex-col h-full gap-y-2.5 pr-3">
                            <div className="sm:col-span-8 flex-grow lg:col-span-7 text-normal mb-3 flex flex-col gap-y-3">
                                <h2 className="text-3xl text-primary">
                                    Add an Attribute
                                </h2>

                                <span className="text-sm">
                                    Create custom form attributes to capture the exact data you need for each asset.
                                </span>

                                <span className="block py-2 text-sm">
                                    Easily tailor fields to specific assets and link them with other existing assets for more detailed tracking.
                                </span>
                            </div>

                            <div className="flex-none w-full text-sm">
                                <button type="button" onClick={toggleCustomAttributeForm} className="text-primary text-start hover:text-secondary">
                                    {
                                        customForm ? (
                                            'Add a pre-defined attribute'
                                        ) : (
                                            'Create a custom attribute'
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                    </>
                }
                contents={
                    <>
                        {
                            state.status === 'fulfilled' ? (
                                <div className="w-full flex flex-col h-full gap-y-2.5">
                                    {
                                        customForm ? (
                                            <div className="w-full flex-grow flex flex-col pb-4 text-sm text-normal gap-y-3">
                                                <span className="px-4 sm:px-6 w-full text-primary">
                                                    Create your custom attribute
                                                </span>

                                                <div className="px-4 sm:px-6 w-full flex flex-col">
                                                    <InputBasic
                                                        name={"label_name"}
                                                        required={true}
                                                        label={"Give your attribute a name"}
                                                        placeHolder={"Name"}
                                                        posting={state.posting}
                                                        value={state.input.label_name}
                                                        error={state.errors.label_name}
                                                        blurHandler={onInputBlur}
                                                        changeHandler={onChangeHandler}
                                                    />

                                                    <span className="text-xs text-slate-500 block pt-3">
                                                        Set a clear name that defines it's purpose. e.g. <span className="text-secondary">'Warranty Expiry Date'</span>.
                                                    </span>
                                                </div>

                                                <div className="w-full px-4 sm:px-6 border-y py-3">
                                                    <div className="w-full text-sm flex flex-row align-middle items-center gap-x-3">
                                                        <div className="flex-none text-normal">
                                                            <span className="pb-2 pr-4">
                                                                Data Type:
                                                            </span>
                                                        </div>

                                                        <div className="flex-grow flex flex-col gap-y-1">
                                                            <DataTypeList
                                                                state={state}
                                                                array={dataTypeArray}
                                                                changeHandler={onChangeListBoxHandler}
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="text-xs text-slate-500 block pt-3">
                                                        Select the data type that best fits the information you want to capture.
                                                    </span>
                                                </div>

                                                <div className="px-4 sm:px-6">
                                                    <ToggleSwitch
                                                        label={'Required'}
                                                        name={'required'}
                                                        disabled={false}
                                                        changeHandler={handleCheckboxChange}
                                                        checked={state.input.required}
                                                    />

                                                    <span className="text-xs text-slate-500 block pt-3">
                                                        This ensures users provide a value before submitting.
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="w-full flex-grow flex flex-col pb-4 gap-y-3">
                                                <ContentZero
                                                    state={state}
                                                    activeGroup={activeGroup}
                                                    activeAttribute={activeAttribute}
                                                    groupAttributes={groupAttributes}
                                                    onChangeGroupsHandler={onChangeGroupsHandler}
                                                    onChangeAttributesHandler={onChangeAttributesHandler}
                                                />

                                                {
                                                    activeAttribute && (
                                                        <React.Fragment>
                                                            <AssetDataType
                                                                selectedDT={attrDataType}
                                                                dataTypes={dataTypeArray}
                                                                activeAttr={activeAttribute}
                                                                changeselectedDT={changeAttrDataType}
                                                            />

                                                            <div className="px-4 sm:px-6">
                                                                <ToggleSwitch
                                                                    label={'Required'}
                                                                    name={'required'}
                                                                    disabled={false}
                                                                    changeHandler={handleCheckboxChange}
                                                                    checked={state.input.required}
                                                                />

                                                                <span className="text-xs text-slate-500 block pt-2">
                                                                    This ensures users provide a value before submitting.
                                                                </span>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            ) : null
                        }
                    </>
                }
            />
        </React.Fragment>
    )
}

const ContentZero = ({ state, onChangeGroupsHandler, activeGroup, onChangeAttributesHandler, activeAttribute, groupAttributes }) => {
    const listBoxId = generateUniqueKey(17)
    const attrBoxId = generateUniqueKey(17)

    const [listBoxWidth, setListBoxWidth] = useState(0)
    const [attrBoxWidth, setAttrBoxWidth] = useState(0)

    useEffect(() => {
        setListBoxWidth(document.getElementById(listBoxId).offsetWidth)
        setAttrBoxWidth(document.getElementById(attrBoxId).offsetWidth)
    }, [listBoxId, listBoxWidth]);

    return (
        <React.Fragment>
            <div className="w-full pb-2 px-4 sm:px-6">
                <div className="pb-3 w-2/3">
                    <ListBox
                        label={"Attribute Grouping:"}
                        width={listBoxWidth}
                        value={state.input.group}
                        eventHandler={(e: any) => onChangeGroupsHandler(e)}
                        button={
                            <>
                                <ListboxButton id={listBoxId} className="relative border w-full rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                                    <span className="flex w-full items-center align-middle">
                                        <div className="flex-grow flex items-center">
                                            <span className="truncate ml-3 font-normal group-data-[selected]:font-semibold">
                                                {activeGroup.name}
                                            </span>
                                        </div>

                                        <span className="fa-light fa-chevron-down focus:text-primary float-end"></span>
                                    </span>
                                </ListboxButton>
                            </>
                        }
                        options={
                            <>
                                {state.data.groups.map((group: any) => (
                                    <ListboxOption key={group.uuid} value={group.uuid} className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-[var(--focus-bg-light)] data-[focus]:text-[var(--color-secondary)]">
                                        <div className="flex items-center">
                                            <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                                {group.name}
                                            </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                            <span className="fa-light fa-check"></span>
                                        </span>
                                    </ListboxOption>
                                ))}
                            </>
                        }
                    />
                </div>

                <ListBox
                    label={"Attribute:"}
                    width={attrBoxWidth}
                    value={state.input.attribute}
                    eventHandler={(e: any) => onChangeAttributesHandler(e)}
                    button={
                        <>
                            <ListboxButton id={attrBoxId} className="relative border w-full rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                                <span className="flex w-full items-center align-middle">
                                    <div className="flex-grow flex items-center">
                                        <span className="ml-3 block truncate">
                                            {
                                                activeAttribute ? (
                                                    <>
                                                        {activeAttribute.label}
                                                    </>
                                                ) : (
                                                    <>
                                                        -- Select an attribute
                                                    </>
                                                )
                                            }
                                        </span>
                                    </div>

                                    <span className="fa-light fa-chevron-down float-end"></span>
                                </span>
                            </ListboxButton>
                        </>
                    }
                    options={
                        <>
                            {
                                groupAttributes.map((attribute: any) => (
                                    <ListboxOption key={attribute.uuid} value={attribute.uuid} className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-[var(--focus-bg-light)] data-[focus]:text-[var(--color-secondary)]">
                                        <div className="flex items-center">
                                            <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                                {attribute.label}
                                            </span>
                                        </div>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                            <span className="fa-light fa-check"></span>
                                        </span>
                                    </ListboxOption>
                                ))
                            }
                        </>
                    }
                />
            </div>
        </React.Fragment>
    )
}

const DataTypeList = ({ state, changeHandler, array }) => {
    const listBoxId = generateUniqueKey(17)
    const [listBoxWidth, setListBoxWidth] = useState(0)

    useEffect(() => {
        const offsetWidth = document.getElementById(listBoxId).offsetWidth;
        setListBoxWidth(offsetWidth)
    }, [listBoxId, listBoxWidth]);

    function getSelectedDataType() {
        return array.find(
            (obj: any) => obj.key === state.input.data_type
        )
    }

    return (
        <React.Fragment>
            <ListBox
                width={listBoxWidth}
                value={state.input.data_type}
                eventHandler={(e: any) => changeHandler(e)}
                button={
                    <>
                        <ListboxButton id={listBoxId} className="relative border w-full rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                            <span className="flex w-full items-center align-middle">
                                <div className="flex-grow flex items-center">
                                    <div className="flex-grow ml-3 flex items-center gap-x-2">
                                        {
                                            getSelectedDataType().key === 'freetext' ? (
                                                <span className="fa-duotone fa-text-size"></span>
                                            ) : getSelectedDataType().key === 'date' ? (
                                                <span className="fa-duotone fa-calendar-days"></span>
                                            ) : getSelectedDataType().key === 'number' ? (
                                                <span className="fa-duotone fa-list-ol"></span>
                                            ) : (
                                                <span className="fa-duotone fa-link"></span>
                                            )
                                        }

                                        <span className="truncate font-normal group-data-[selected]:font-semibold">
                                            {getSelectedDataType().value}
                                        </span>
                                    </div>
                                </div>

                                <span className="fa-light fa-chevron-down float-end"></span>
                            </span>
                        </ListboxButton>
                    </>
                }
                options={
                    <>
                        {
                            array.map((dataType: any) => (
                                <ListboxOption key={dataType.key} value={dataType.key} className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-[var(--focus-bg-color)] data-[focus]:text-[var(--color-primary)]">
                                    <div className="flex items-center">
                                        <div className="flex-grow flex items-center gap-x-2">
                                            {
                                                dataType.key === 'freetext' ? (
                                                    <span className="fa-duotone fa-text-size"></span>
                                                ) : dataType.key === 'date' ? (
                                                    <span className="fa-duotone fa-calendar-days"></span>
                                                ) : dataType.key === 'number' ? (
                                                    <span className="fa-duotone fa-list-ol"></span>
                                                ) : (
                                                    <span className="fa-duotone fa-link"></span>
                                                )
                                            }

                                            <span className="truncate font-normal group-data-[selected]:font-semibold">
                                                {dataType.value}
                                            </span>
                                        </div>
                                    </div>

                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                        <span className="fa-light fa-check"></span>
                                    </span>
                                </ListboxOption>
                            ))
                        }
                    </>
                }
            />
        </React.Fragment>
    )
}