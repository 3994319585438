import React, { useEffect, useState } from "react"
import { ListboxButton, ListboxOption, MenuButton, MenuItem } from "@headlessui/react";

import DropDown from "../../lib/fragments/DropDown";
import HttpServices from "../../services/HttpServices";
import { Loading } from "../../components/utils/Loading";
import { generateUniqueKey } from "../../utils/Helpers";
import { ListBox } from "../../lib/fragments/ListBox";

export const AssetDataType = ({ activeAttr, selectedDT, dataTypes, changeselectedDT }) => {
    const [state, setstate] = useState({
        httpStatus: 200,
        status: 'pending',
        input: {
            entity: null,
            collection: null
        },
        data: {
            entities: null,
            collection: null
        },
    })

    const [entityCollection, setEntitycollection] = useState(null)

    const getLinkedList = async () => {
        let entities = []
        let collection = []
        let { status } = state

        try {
            let response: any = null
            response = await HttpServices.httpGet('/api/v1/assets-management/linked/entities')

            if (response.status === 200) {
                const payload: any = response.data.payload
                collection = payload.collection
                status = 'fulfilled'

                payload.collection.forEach((coll: any) => {
                    const hasCollection = coll.collection.length > 1 ? true : false

                    entities.push({
                        name: coll.name,
                        uuid: coll.uuid,
                        hasCollection: hasCollection,
                    })
                })
            } else {
                status = 'rejected'
            }
        } catch (error) {
            console.log(error);
            status = 'rejected'
        }

        setstate((prevState: any) => ({
            ...prevState,
            status,
            data: {
                ...prevState.data,
                entities: entities,
                collection: collection,
            },
        }));
    }

    useEffect(() => {
        console.log('activeAttr', activeAttr);

        if (activeAttr?.mutable === 'Y') {
            if (activeAttr?.data_type === 'linked' || selectedDT !== 'linked') {
                setstate(prev => ({ ...prev, status: 'fulfilled' }));
            } else if (!state?.data) {
                getLinkedList()
            }
        } else {
            setstate(prev => ({ ...prev, status: 'fulfilled' }));
        }
    }, [activeAttr]);

    const onChangeDataType = (newDataType: any) => {
        changeselectedDT(newDataType)

        if (activeAttr?.data_type === 'linked') {
            setstate(prev => ({ ...prev, status: 'fulfilled' }));
        } else {
            if (newDataType === 'linked') {
                let { data } = state

                if (!data.collection) {
                    setstate(prev => ({ ...prev, status: 'pending' }));

                    getLinkedList()
                }
            }
        }
    }

    const onChangeEntityHandler = (e: any) => {
        let { data } = state
        let _entityCollection: any = null

        if (e.hasCollection) {
            _entityCollection = data.collection.find(
                (obj: any) => obj.uuid === e.uuid
            )
        }

        console.log('_entityCollection', _entityCollection);

        setEntitycollection(_entityCollection ? _entityCollection.collection : null);

        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                entity: e,
                collection: null
            }
        }))
    }

    const onChangeCollectionHandler = (e: any) => {
        setstate((prev) => ({
            ...prev,
            input: {
                ...prev.input,
                collection: e,
            }
        }))
    }

    return (
        <React.Fragment>
            <div className="w-full border-y text-sm px-4 sm:px-6 flex flex-col gap-y-2 pt-1.5 pb-3">
                <div className="flex-none text-normal">
                    <span className="text-xs text-slate-500 block leading-5">
                        The <span className="text-primary">data type</span> that best fits the information to be captured, or link it to an existing item.
                    </span>
                </div>

                <div className="flex-grow flex flex-row text-normal items-center gap-x-3 pb-1">
                    <div className="flex-grow">
                        {(() => {
                            const iconMap = {
                                freetext: "fa-text-size",
                                date: "fa-calendar-days",
                                number: "fa-list-ol",
                                linked: "fa-link",
                            };

                            const iconClass = iconMap[selectedDT] || iconMap.linked;
                            const dataType = selectedDT === 'linked' ? (
                                <span className="text-sm">linked to:</span>
                            ) : selectedDT

                            return (
                                <div className="flex-grow flex flex-col text-sm items-start gap-x-2">
                                    <div className="flex items-center gap-x-2">
                                        <span className={`fa-duotone ${iconClass}`}></span>
                                        <span className="normal-case">
                                            {dataType}
                                        </span>
                                    </div>
                                </div>
                            );
                        })()}
                    </div>

                    {
                        activeAttr.mutable === 'Y' ? (
                            <div className="flex-none border border-stone-300 rounded-md flex items-center gap-x-2 pl-2 pr-1 text-sm text-normal">
                                <p className="flex-none">Change To</p>
                                <span className="text-gray-500">|</span>

                                <div className="none">
                                    <DropDown
                                        button={
                                            <MenuButton className="rounded-md disabled:cursor-not-allowed disabled:text-gray-400 bg-white px-1.5 my-1 hover:bg-gray-200 focus:ring-primary focus:outline-primary hover:bozrder-stone-400">
                                                <span className="fa-light fa-chevron-down"></span>
                                            </MenuButton>
                                        }
                                        items={
                                            <div className="text-sm w-40">
                                                {
                                                    dataTypes.map((dataType: any) => (
                                                        <div key={dataType.key}>
                                                            {(() => {
                                                                const iconMap = {
                                                                    freetext: "fa-text-size",
                                                                    date: "fa-calendar-days",
                                                                    number: "fa-list-ol",
                                                                    linked: "fa-link",
                                                                };

                                                                if (selectedDT === dataType.key) {
                                                                    return null;
                                                                }

                                                                if (
                                                                    (selectedDT === 'linked' && dataType.key === 'freetext') ||
                                                                    (selectedDT === 'freetext' && dataType.key === 'linked') ||
                                                                    (selectedDT === 'number' && dataType.key === 'freetext') ||
                                                                    (dataType.key === selectedDT)
                                                                ) {
                                                                    dataType = activeAttr.data_type === 'number' ? (
                                                                        selectedDT === 'freetext' ? {
                                                                            key: 'number',
                                                                            value: 'Number'
                                                                        } : dataType
                                                                    ) : dataType

                                                                    const iconClass = iconMap[dataType.key] || iconMap.freetext;

                                                                    return (
                                                                        <MenuItem>
                                                                            <button
                                                                                onClick={() => onChangeDataType(dataType.key)}
                                                                                type="button"
                                                                                className="flex w-full gap-x-3 items-center rounded-md px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                                                            >
                                                                                <span className={`fa-duotone ${iconClass} fa-lg w-1/4`}></span>
                                                                                <span className="w-3/4 text-start">{dataType.value}</span>
                                                                            </button>
                                                                        </MenuItem>
                                                                    );
                                                                }

                                                                return null;
                                                            })()}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        ) : null
                    }
                </div>

                {
                    activeAttr.mutable === 'Y' && selectedDT === 'linked' ? (
                        <>
                            {
                                state.status === 'fulfilled' ? (
                                    <div className="flex-grow flex flex-col text-sm text-normal">
                                        {
                                            activeAttr.linked_to ? (
                                                <>
                                                    {(() => {
                                                        const linkedEntity = activeAttr.data_type === 'linked' && activeAttr.linked_to ? (
                                                            <span>{activeAttr.linked_to.category} {'>'} {activeAttr.linked_to.entity}</span>
                                                        ) : null;

                                                        return (
                                                            <div className="flex-grow flex flex-col text-sm items-start gap-x-2">
                                                                <span className="text-secondary">
                                                                    {linkedEntity}
                                                                </span>
                                                            </div>
                                                        );
                                                    })()}
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        selectedDT === 'linked' ? (
                                                            <div className="pb-2 w-full flex flex-col">
                                                                <CollectionListBox
                                                                    state={state}
                                                                    entities={state.data.entities}
                                                                    setCollection={entityCollection}
                                                                    entityHandler={onChangeEntityHandler}
                                                                    collectionHandler={onChangeCollectionHandler}
                                                                />
                                                            </div>
                                                        ) : null
                                                    }

                                                </>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div className="w-full px-4">
                                        <Loading />
                                    </div>
                                )
                            }

                        </>
                    ) : null
                }
            </div>
        </React.Fragment>
    )
}

const CollectionListBox = ({ entities, state, setCollection, entityHandler, collectionHandler }) => {
    const listBoxId = generateUniqueKey(17)
    const collectionBoxId = generateUniqueKey(17)

    const [listBoxWidth, setListBoxWidth] = useState(0)
    const [collectionBoxWidth, setCollectionBoxWidth] = useState(0)

    useEffect(() => {
        setListBoxWidth(document.getElementById(listBoxId).offsetWidth)
        setCollectionBoxWidth(document.getElementById(collectionBoxId).offsetWidth)
    }, [
        listBoxId,
        listBoxWidth,
        collectionBoxId,
        setCollectionBoxWidth
    ]);

    return (
        <React.Fragment>
            <div className="w-2/3">
                <ListBox
                    width={listBoxWidth}
                    value={state.input.entity}
                    eventHandler={(e: any) => entityHandler(e)}
                    button={
                        <>
                            <ListboxButton id={listBoxId} className="relative border w-full rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                                <span className="flex w-full items-center align-middle">
                                    <div className="flex-grow flex items-center">
                                        <span className="truncate ml-3 font-normal group-data-[selected]:font-semibold">
                                            {
                                                state.input.entity ? (
                                                    <>
                                                        {state.input.entity.name}
                                                    </>
                                                ) : (
                                                    <>
                                                        -- Select entity to link
                                                    </>
                                                )
                                            }
                                        </span>
                                    </div>

                                    <span className="fa-light fa-chevron-down focus:text-primary float-end"></span>
                                </span>
                            </ListboxButton>
                        </>
                    }
                    options={
                        <>
                            {entities.map((entity: any) => (
                                <ListboxOption key={entity.uuid} value={entity} className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-[var(--focus-bg-light)] data-[focus]:text-[var(--color-secondary)]">
                                    <div className="flex items-center">
                                        <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {entity.name}
                                        </span>
                                    </div>

                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                        <span className="fa-light fa-check"></span>
                                    </span>
                                </ListboxOption>
                            ))}
                        </>
                    }
                />
            </div>

            <div className={`w-full ${setCollection ? 'pt-3.5' : null}`} id={collectionBoxId}>
                {
                    state.input.entity && (
                        setCollection && (
                            <ListBox
                                width={collectionBoxWidth}
                                value={state.input.entity}
                                eventHandler={(e: any) => collectionHandler(e)}
                                button={
                                    <>
                                        <ListboxButton id={listBoxId} className="relative border w-full rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                                            <span className="flex w-full items-center align-middle">
                                                <div className="flex-grow flex items-center">
                                                    <span className="truncate ml-3 font-normal group-data-[selected]:font-semibold">
                                                        {
                                                            state.input.collection ? (
                                                                <>
                                                                    {state.input.collection.name}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    -- Select entity to link
                                                                </>
                                                            )
                                                        }
                                                    </span>
                                                </div>

                                                <span className="fa-light fa-chevron-down focus:text-primary float-end"></span>
                                            </span>
                                        </ListboxButton>
                                    </>
                                }
                                options={
                                    <>
                                        {setCollection.map((collection: any) => (
                                            <ListboxOption key={collection.uuid} value={collection} className="group relative cursor-default select-none py-2 pl-3 pr-9 text-slate-900 data-[focus]:bg-[var(--focus-bg-light)] data-[focus]:text-[var(--color-secondary)]">
                                                <div className="flex items-center">
                                                    <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                                        {collection.name}
                                                    </span>
                                                </div>

                                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                    <span className="fa-light fa-check"></span>
                                                </span>
                                            </ListboxOption>
                                        ))}
                                    </>
                                }
                            />
                        )
                    )
                }
            </div>
        </React.Fragment>
    )
}