import { Helmet } from "react-helmet"
import React, { useEffect, useState } from "react"

import Config from "../../api/Config"
import { ASSETS } from "../../api/Registry"
import { DetailsPanel } from "./DetailsPanel"
import { classNames } from "../../utils/Helpers"
import { CreateCategory } from "./CreateCategory"
import HttpServices from "../../services/HttpServices"
import { AssetTypesListing } from "./AssetTypesListing"
import { Loading } from "../../components/utils/Loading"

export const AssetConfiguration = () => {
    const [state, setstate] = useState({
        httpStatus: 200,
        status: 'pending',
        selectedCategory: null,
        data: {
            categories: null,
        },
        input: {
            search: ''
        },
        show: {
            createType: false,
            createCategory: false,
        }
    })

    const [activeType, setActiveType] = React.useState(null)
    const [newCategory, setNewCategory] = React.useState(null)
    const [activeCategory, setActiveCategory] = React.useState(null)

    const categorySelection = (category: any) => {
        if (activeCategory && activeCategory.uuid === category.uuid) {
            setActiveCategory(null)
        } else {
            setActiveCategory(category)
        }

        setActiveType(null)
    }

    useEffect(() => {
        const fetchAssetCategories = async () => {
            let { status } = state
            let { httpStatus } = state

            let categories = null

            try {
                const response: any = await HttpServices.httpGet(ASSETS.ALL_CATEGORIES)
                httpStatus = response.status

                if (httpStatus === 200) {
                    const payload: any = response.data.payload

                    status = 'fulfilled'
                    categories = payload.categories
                } else {
                    status = 'rejected'
                }
            } catch (error) {
                status = 'rejected'
                httpStatus = 500
            }

            setstate((prevState) => ({
                ...prevState, status,
                httpStatus,
                data: {
                    ...prevState.data,
                    categories: categories,
                },
            }));
        }

        fetchAssetCategories()
    }, [])



    const toggleCreateCategoryPanel = () => {
        setstate((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                createCategory: !prev.show.createCategory
            }
        }))
    }

    const onCreateCategoryComplete = (actionData: any) => {
        toggleCreateCategoryPanel()
        setNewCategory(actionData)
    }

    const configureAssetType = (type: any) => {
        setActiveType(type);
    }

    return (
        <>
            <Helmet>
                <title>Asset Categories - {Config.APP_NAME}</title>
            </Helmet>

            <div className="flex flex-col gap-x-3 px-3.5 w-full mx-auto py-3 md:h-full lg:h-full container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                {
                    state.status === 'rejected' ? (
                        <></>
                    ) : state.status === 'fulfilled' ? (
                        <div className="flex-grow w-full overflow-auto h-full flex flex-row">
                            <div className="flex-1 md:w-1/3 overflow-auto thin-scrollbar h-full pt-4">
                                <p className="text-base md:mb-3 text-primary text-wrap">
                                    Asset Categories
                                    <span className="block py-1 5 text-sm text-gray-600">
                                        Click on a category to view details
                                    </span>
                                </p>

                                <div className="w-full pb-3 flex flex-row-reverse px-2">
                                    <button type="button" onClick={toggleCreateCategoryPanel} className="flex flex-row items-center gap-x-3 align-middle text-sm text-primary hover:text-secondary">
                                        <i className="fad fa-plus fa-lg"></i>
                                        Create Category
                                    </button>
                                </div>

                                <div className="pr-2">
                                    {
                                        state.data.categories.map((category: any) => {
                                            return (
                                                <div key={category.uuid} className="bg-inherit">
                                                    <h2 className="mb-0" id={category.uuid}>
                                                        <button
                                                            className={
                                                                classNames(
                                                                    activeCategory && activeCategory.uuid === category.uuid ? `text-primary bg-light border-b border-primary` : 'text-stone-600 border-b border-neutral-200',

                                                                    'group relative flex w-full align-middle items-center border-0 px-2 py-3.5 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
                                                                )
                                                            }
                                                            type="button"
                                                            onClick={() => categorySelection(category)}
                                                            aria-expanded="true"
                                                            aria-controls="collapseOne"
                                                        >
                                                            {category.name}
                                                            <span
                                                                className={`${activeCategory && activeCategory.uuid === category.uuid
                                                                    ? `rotate-[-180deg]`
                                                                    : `rotate-0 fill-[#212529]  dark:fill-white`
                                                                    } ml-auto h-5 w-5 shrink-0 transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </h2>

                                                    {
                                                        activeCategory && activeCategory.uuid === category.uuid ? (
                                                            <AssetTypesListing
                                                                category={category}
                                                                activeType={activeType}
                                                                configureAssetType={configureAssetType}
                                                            />
                                                        ) : null
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        newCategory && (
                                            <div key={newCategory.uuid} className="bg-inherit">
                                                <h2 className="mb-0" id={newCategory.uuid}>
                                                    <button
                                                        className={
                                                            classNames(
                                                                activeCategory && activeCategory.uuid === newCategory.uuid ? `text-primary bg-green-100 border-b border-green-200` : 'text-stone-600 border-b border-neutral-200',
                                                                'group relative flex w-full align-middle items-center border-0 px-2 py-3.5 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none'
                                                            )
                                                        }
                                                        type="button"
                                                        onClick={() => categorySelection(newCategory)}
                                                        aria-expanded="true"
                                                        aria-controls="collapseOne"
                                                    >
                                                        {newCategory.name}
                                                        <span
                                                            className={`${activeCategory && activeCategory.uuid === newCategory.uuid
                                                                ? `rotate-[-180deg]`
                                                                : `rotate-0 fill-[#212529]  dark:fill-white`
                                                                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </h2>

                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="w-px bg-gray-300 mx-2"></div>

                            <div className="flex-2 w-2/3 h-full overflow-auto thin-scrollbar px-3 pb-3 flex flex-col">
                                <DetailsPanel category={activeCategory} type={activeType} />
                            </div>
                        </div>
                    ) : (
                        <div className="flex-grow w-full overflow-auto h-full flex flex-row py-4 justify-center align-middle items-center">
                            <Loading />
                        </div>
                    )
                }

                <CreateCategory
                    show={state.show.createCategory}
                    onComplete={onCreateCategoryComplete}
                    showOrHide={toggleCreateCategoryPanel}
                />
            </div>
        </>
    )
}