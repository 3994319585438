import { Menu, MenuItems } from '@headlessui/react'

export default function DropDown({ button, items }) {
    return (
        <Menu as="div" className="relative inline-block text-left w-full">
            <div>
                {button}
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 max-w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                {
                    items
                }
            </MenuItems>
        </Menu>
    )
}
