import React, { useEffect, useState } from "react"
import { DialogTitle, MenuButton, MenuItem } from "@headlessui/react"

import { ASSETS } from "../../api/Registry"
import { AddAttribute } from "./AddAttribute"
import DropDown from "../../lib/fragments/DropDown"
import HttpServices from "../../services/HttpServices"
import { API_RouteReplace } from "../../utils/Helpers"
import { Loading } from "../../components/utils/Loading"
import { SortableList } from "../../lib/dnd/SortableList"
import { ConsentModal } from "../../lib/fragments/ConsentModal"
import { ModuleBreadCrumb } from "../../lib/fragments/ModuleBreadCrumb"

export const DetailsPanel = ({ category, type }) => {
    const [state, setstate] = useState({
        httpStatus: 200,
        status: 'pending',
        data: {
            forms: null
        },
        show: {
            addAttribute: false
        }
    })

    let sectionCounter = 0;
    const [formData, setFormData] = useState(null)

    let sectionArray = []
    let mainArray = []

    const [consentAttr, setConsentAttr] = useState(null)
    const [formAttributes, setFormAttributes] = useState(null)
    const [showConsentModal, setConsetModal] = useState(false)

    function setBreadCrumb() {
        let breadCrumb = [
            { linkItem: false, title: "Asset Management" },
            { linkItem: false, title: "Configuration" },
        ]

        if (category) {
            breadCrumb = [
                { linkItem: false, title: "Asset Management" },
                { linkItem: false, title: category.name },
            ]

            if (type) {
                breadCrumb = [
                    ...breadCrumb,
                    { linkItem: true, title: type.name }
                ];
            }
        }

        return breadCrumb
    }

    useEffect(() => {
        if (type) {
            const fetchAssetTypeForm = async () => {
                setstate((prevState: any) => ({
                    ...prevState,
                    status: 'pending'
                }))

                let forms = null
                let { status } = state
                let { httpStatus } = state

                try {
                    let response: any = null
                    let assetFormRoute = null

                    assetFormRoute = API_RouteReplace(ASSETS.ASSET_FORMS, ':category', category.uuid)
                    assetFormRoute = API_RouteReplace(assetFormRoute, ':type', type.uuid)

                    response = await HttpServices.httpGet(assetFormRoute)
                    httpStatus = response.status

                    if (httpStatus === 200) {
                        const payload: any = response.data.payload
                        let assetForm = []

                        payload.form.forEach((data: any, index: any) => {
                            let mainDump = {
                                id: data.key,
                                block: 'section',
                                label: data.label,
                                subtitle: data.subtitle,
                            }

                            assetForm.push(mainDump)
                            sectionArray.push(mainDump)

                            data.attributes.forEach((dataObject: any) => {
                                let auxiliaryDump = {
                                    id: dataObject.key,
                                    block: 'attribute',
                                    attribute: dataObject.attribute,
                                    label: dataObject.label,
                                    subtitle: dataObject.subtitle,
                                    basic: dataObject.basic,
                                    required: dataObject.required,
                                    mutable: dataObject.mutable,
                                    dataType: dataObject.data_type,
                                }

                                assetForm.push(auxiliaryDump)
                                sectionArray.push(auxiliaryDump)
                            })

                            mainArray.push(sectionArray)

                            if (index === (payload.form.length - 1)) {
                                mainArray.push(sectionArray)
                            }
                        });

                        setFormData(assetForm)




                        status = 'fulfilled'

                        forms = payload.forms
                        setFormAttributes(forms.attributes)
                    } else {
                        status = 'rejected'
                    }
                } catch (error) {
                    console.log(error);

                    status = 'rejected'
                    httpStatus = 500
                }

                setstate((prevState: any) => ({
                    ...prevState, status,
                    httpStatus,
                    data: {
                        ...prevState.data,
                        forms: forms,
                    },
                }));
            }

            fetchAssetTypeForm()
        }
    }, [category, type])

    const getReorderedList = async (reorderedAttr: any) => {
        sectionCounter = 0
        setFormData(reorderedAttr)
        console.log('Reordered', reorderedAttr);

        // syncUpdatedAttributeList(reorderedAttr, originalAttr)
    }

    const toggleAddAttributeModal = () => {
        setstate((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                addAttribute: !prev.show.addAttribute
            }
        }))
    }

    const onAddAttributeComplete = (actionData: any) => {
        formAttributes.push(actionData)

        console.log('Action Data', actionData);
        console.log('Action Data', formAttributes);
    }

    const removeAttributeFromForm = (attribute: any) => {
        setConsentAttr(attribute)
        setConsetModal(!showConsentModal)
    }

    const syncUpdatedAttributeList = async (newAttrList: any, originalAttr: any) => {
        let { httpStatus } = state

        try {
            let formData = new FormData()
            let attrArray: any = []

            newAttrList.forEach((attribute: any) => {
                attrArray.push(attribute);
            });

            formData.append('attribute', JSON.stringify(attrArray))

            let response: any = null
            let attributeRoute = null

            attributeRoute = API_RouteReplace(ASSETS.FORM_ATTRIBUTES, ':category', category.uuid)
            attributeRoute = API_RouteReplace(attributeRoute, ':type', type.uuid)

            response = await HttpServices.httpPut(attributeRoute, formData)
            httpStatus = response.status

            if (httpStatus !== 200) {
                setFormAttributes(originalAttr)
            }
        } catch (error) {
            httpStatus = 500
        }
    }

    const onConsentAccepted = () => {
        let attrArray = [...formAttributes]
        let originalAttr = [...formAttributes]

        setConsetModal(!showConsentModal)
        let attrIndex = formAttributes.findIndex((obj: any) => obj.id === consentAttr.id)

        attrArray.splice(attrIndex, 1)
        setFormAttributes(attrArray)

        setConsentAttr(null)
        syncUpdatedAttributeList(attrArray, originalAttr)
    }

    return (
        <React.Fragment>
            {
                category ? (
                    <>
                        <div className="w-full flex-none">
                            <div className="promotion flex flex-col relative z-[3] w-full pt-4">
                                <div className="header-container opacity-100 flex flex-col justify-center flex-1">
                                    <ModuleBreadCrumb
                                        breadCrumb={setBreadCrumb()}
                                    />
                                </div>
                            </div>

                            {
                                type ? (
                                    <>
                                        <div className="header-title align-middle flex flex-nowrap title-transition">
                                            <h1 className="title md:text-lg text-base font-meidum tracking-wider leading-9 md:pb-2.5 pb-3 text-primary">
                                                {type.name}
                                            </h1>
                                        </div>

                                        <p className="text-sm md:mb-5 text-gray-700 text-wrap">
                                            {type.description}
                                        </p>

                                        {
                                            state.status === 'rejected' ? (
                                                <>

                                                </>
                                            ) : state.status === 'fulfilled' ? (
                                                <>
                                                    <div className="w-full pb-3 flex flex-row align-middle items-center gap-x-4">
                                                        <button type="button" onClick={toggleAddAttributeModal} className="flex flex-row align-middle items-center gap-x-1.5 text-primary hover:text-secondary">
                                                            <span className="fa-regular fa-plus"></span>
                                                            <span className="text-sm">Add Attribute</span>
                                                        </button>

                                                        <span className="border-l py-3 text-gray-400"></span>

                                                        <button type="button" className="flex flex-row align-middle items-center gap-x-1.5 text-gray-500 hover:text-gray-600">
                                                            <span className="text-sm">Help</span>
                                                            <span className="fa-regular fa-circle-info"></span>
                                                        </button>
                                                    </div>

                                                    <div className="w-full XSwe pb-40">
                                                        <SortableList
                                                            items={formData}
                                                            onChange={(newItems) => getReorderedList(newItems)}
                                                            renderItem={(attributeData: any) => {
                                                                console.log('Counter', sectionCounter);

                                                                if (attributeData.block === 'section') {

                                                                    sectionCounter++;

                                                                    return (
                                                                        <>
                                                                            <div className="py-2 px-3 text-xs bg-stone-200 text-normal w-full">
                                                                                Section {sectionCounter}
                                                                            </div>

                                                                            <div key={attributeData.id} className="w-full flex flex-col px-3">
                                                                                <div className="w-full pb-2">
                                                                                    <h2 className="text-base text-gray-800 pb-1">
                                                                                        {attributeData.label}
                                                                                    </h2>

                                                                                    <div className="text-gray-600 bg-gray-10 w-full">
                                                                                        <span className="block text-xs">
                                                                                            {attributeData.subtitle}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="flex-grow flex flex-row py-2">
                                                                                    <button type="button" onClick={toggleAddAttributeModal} className="flex flex-row align-middle items-center gap-x-1.5 text-primary hover:text-secondary">
                                                                                        <span className="fa-regular fa-plus"></span>
                                                                                        <span className="text-sm">Add Attribute</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                } else {

                                                                    return (
                                                                        <>
                                                                            <div key={attributeData.id} className="px-3">
                                                                                <SortableList.Item id={attributeData.id}>
                                                                                    <SortableList.DragHandle />

                                                                                    <div className="px-4 py-2 sm:px-3 text-sm bg-teal-00 w-full border-b">
                                                                                        <div className="flex flex-col items-start justify-between w-full">
                                                                                            <div className="flex flex-row items-center justify-between gap-x-3 w-full">
                                                                                                <div className="flex-grow">
                                                                                                    <h3 className="text-sm text-normal flex-grow">
                                                                                                        {attributeData.label}
                                                                                                    </h3>
                                                                                                </div>

                                                                                                <div className="flex flex-row-reverse items-center gap-x-3 text-sm text-normal">
                                                                                                    {(() => {
                                                                                                        const iconMap = {
                                                                                                            freetext: "fa-text-size",
                                                                                                            date: "fa-calendar-days",
                                                                                                            number: "fa-list-ol",
                                                                                                            linked: "fa-link",
                                                                                                        };

                                                                                                        const iconClass = iconMap[attributeData.dataType] || iconMap.linked;
                                                                                                        const dataType = attributeData.dataType === 'linked' ? (
                                                                                                            <span className="text-sm">linked to:</span>
                                                                                                        ) : attributeData.dataType

                                                                                                        const linkedEntity = attributeData.dataType === 'linked' && attributeData.linked ? (
                                                                                                            <span>{attributeData.linked.category} {'>'} {attributeData.linked.entity}</span>
                                                                                                        ) : null;

                                                                                                        return (
                                                                                                            <div className="flex-grow flex flex-col text-sm items-start gap-x-2">
                                                                                                                <div className="flex items-center gap-x-2">
                                                                                                                    <span className={`fa-duotone ${iconClass}`}></span>
                                                                                                                    <span>
                                                                                                                        {dataType}
                                                                                                                    </span>
                                                                                                                </div>

                                                                                                                <span className="text-secondary">
                                                                                                                    {linkedEntity}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        );
                                                                                                    })()}
                                                                                                </div>
                                                                                            </div>

                                                                                            <p className="max-w-2xl text-sm text-gray-500">
                                                                                                Description for - {attributeData.label}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="mt-2 flex items-center justify-between">
                                                                                            <p className="text-sm text-gray-500">Required</p>
                                                                                            <a href="#" className="font-mdium text-indigo-600 hover:text-indigo-500">Edit</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </SortableList.Item>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            }}
                                                        />




                                                        <SortableList
                                                            items={mainArray}
                                                            onChange={(newItems) => getReorderedList(newItems)}
                                                            renderItem={(formAttr: any) => (
                                                                <>
                                                                    <div id={formAttr.id} className="rounded-md border border-gray-300 pb-4 px-1 hover:shadow-lg">
                                                                        ---{formAttr.id}---
                                                                        {
                                                                            formAttr.map((sortableArray: any, index: any) => {
                                                                                console.log('OOOOOOOOOOO{', sortableArray.block);

                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            sortableArray.block === 'section' ? (
                                                                                                <div key={sortableArray.id} className="w-full flex flex-col px-3">
                                                                                                    <div className="w-full pt-3 pb-2">
                                                                                                        <h2 className="text-base text-gray-800 pb-1">
                                                                                                            {sortableArray.label}
                                                                                                        </h2>

                                                                                                        <div className="text-gray-600 bg-gray-10 w-full">
                                                                                                            <span className="block text-xs">
                                                                                                                {sortableArray.subtitle}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="flex-grow flex flex-row py-2">
                                                                                                        <button type="button" onClick={toggleAddAttributeModal} className="flex flex-row align-middle items-center gap-x-1.5 text-primary hover:text-secondary">
                                                                                                            <span className="fa-regular fa-plus"></span>
                                                                                                            <span className="text-sm">Add Attribute</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div key={sortableArray.id} className="px-3">
                                                                                                    <SortableList.Item id={sortableArray.id}>
                                                                                                        <SortableList.DragHandle />

                                                                                                        <div className="flex flex-row align-middle items-center w-full">
                                                                                                            <div className="flex-1 w-1/3">
                                                                                                                <span className="text-sm flex flex-col gap-x-2 py-2">
                                                                                                                    <span className="none">{sortableArray.label}</span>

                                                                                                                    {
                                                                                                                        sortableArray.required ? (
                                                                                                                            <span className="rounded-md text-xs text-gray-500">
                                                                                                                                Required
                                                                                                                            </span>
                                                                                                                        ) : (
                                                                                                                            <span className="rounded-md text-xs text-gray-500">
                                                                                                                                -
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>

                                                                                                            <div className="flex w-2/3 items-center justify-between">
                                                                                                                <div className="flex-grow flex flex-row items-center gap-x-3 text-sm text-normal">
                                                                                                                    {(() => {
                                                                                                                        const iconMap = {
                                                                                                                            freetext: "fa-text-size",
                                                                                                                            date: "fa-calendar-days",
                                                                                                                            number: "fa-list-ol",
                                                                                                                            linked: "fa-link",
                                                                                                                        };

                                                                                                                        const iconClass = iconMap[sortableArray.dataType] || iconMap.linked;
                                                                                                                        const dataType = sortableArray.dataType === 'linked' ? (
                                                                                                                            <span className="text-xs">linked to:</span>
                                                                                                                        ) : sortableArray.dataType

                                                                                                                        const linkedEntity = sortableArray.dataType === 'linked' && sortableArray.linked ? (
                                                                                                                            <span>{sortableArray.linked.category} {'>'} {sortableArray.linked.entity}</span>
                                                                                                                        ) : null;

                                                                                                                        return (
                                                                                                                            <div className="flex-grow flex flex-col text-sm items-start gap-x-2">
                                                                                                                                <div className="flex items-center gap-x-2">
                                                                                                                                    <span className={`fa-duotone ${iconClass}`}></span>
                                                                                                                                    <span>
                                                                                                                                        {dataType}
                                                                                                                                    </span>
                                                                                                                                </div>

                                                                                                                                <span className="text-secondary">
                                                                                                                                    {linkedEntity}
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    })()}
                                                                                                                </div>


                                                                                                                <div className="flex-none flex items-center px-2">
                                                                                                                    <DropDown
                                                                                                                        button={
                                                                                                                            <MenuButton disabled={sortableArray.basic === 'Y' ? true : false} className="rounded-md disabled:cursor-not-allowed disabled:text-gray-400 bg-white px-1.5 py-1.5 text-sm text-gray-500 hover:bg-gray-100">
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                                                                                                                                    <circle cx="5" cy="12" r="2" />
                                                                                                                                    <circle cx="12" cy="12" r="2" />
                                                                                                                                    <circle cx="19" cy="12" r="2" />
                                                                                                                                </svg>
                                                                                                                            </MenuButton>
                                                                                                                        }
                                                                                                                        items={
                                                                                                                            <div className="text-sm">
                                                                                                                                <MenuItem>
                                                                                                                                    <button type="button" className="flex w-full flex-row gap-x-3 align-middle items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                                                                                                                        <span className="fa-light fa-pen-to-square fa-lg"></span>

                                                                                                                                        Edit Item
                                                                                                                                    </button>
                                                                                                                                </MenuItem>

                                                                                                                                <MenuItem>
                                                                                                                                    <button type="button" onClick={() => removeAttributeFromForm(sortableArray)} disabled={sortableArray.basic === 'Y' ? true : false} className="flex w-full flex-row gap-x-3 align-middle disabled:cursor-not-allowed disabled:text-red-400 disabled:hover:text-red-400 disabled:data-[focus]:bg-red-100 items-center px-4 py-2 text-sm text-red-600 data-[focus]:bg-red-100 data-[focus]:text-red-700">
                                                                                                                                        <span className="fa-light fa-trash fa-lg"></span>

                                                                                                                                        Delete Item
                                                                                                                                    </button>
                                                                                                                                </MenuItem>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </SortableList.Item>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Loading />
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        Select an asset type from the list
                                    </>
                                )
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <div className="w-full flex-none">
                            <div className="promotion flex flex-col relative z-[3] w-full pt-4">
                                <div className="header-container opacity-100 flex flex-col justify-center flex-1">
                                    <ModuleBreadCrumb
                                        breadCrumb={setBreadCrumb()}
                                    />

                                    <div className="header-title align-middle flex flex-nowrap title-transition">
                                        <h1 className="title md:text-lg text-base font-meidum tracking-wider leading-9 md:pb-2.5 pb-3 text-primary">
                                            Asset Configuration
                                        </h1>
                                    </div>
                                </div>
                            </div >

                            <p className="text-sm md:mb-5 text-gray-700 text-wrap">
                                Organize and classify your assets into categories, types, departments, or purpose for efficient searching and reporting
                            </p>
                        </div >

                        <div className="flex-grow flex flex-col h-full rounded-md md:flex-row md:space-x-4 justify-center border-0 border-gray-300 border-dashed">
                            <div className="text-center flex w-full align-middle md:py-6 py-4 items-center">
                                <div className="text-center flex w-full flex-col align-middle items-center">
                                    <svg
                                        className="mx-auto h-20 w-20 text-secondary"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            opacity="0.5"
                                            d="M20 6L3 6"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        ></path>
                                        <path
                                            opacity="0.5"
                                            d="M20 11L3 11"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        ></path>
                                        <path
                                            opacity="0.5"
                                            d="M11 16H3"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        ></path>
                                        <path
                                            d="M14 18L17.5 15L21 18"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>

                                    <div className="text-s w-full ml-3 text-stone-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded bg-white text-gray-600"
                                        >
                                            <span>
                                                Select an item from the list
                                            </span>
                                        </label>
                                        <p className="pl-1"></p>
                                        <p className="text-xs text-stone-500">to view configured details</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            <AddAttribute
                type={type}
                category={category}
                show={state.show.addAttribute}
                onComplete={onAddAttributeComplete}
                showOrHide={toggleAddAttributeModal}
            />

            <ConsentModal
                actionName={'Delete'}
                show={showConsentModal}
                execAction={onConsentAccepted}
                showOrHide={removeAttributeFromForm}
                content={
                    <>
                        <DialogTitle as="h3" className="text-base leading-6 tracking-wide text-red-600">
                            Delete attribute?
                        </DialogTitle>

                        <div className="mt-2">
                            <p className="text-sm text-normal">
                                Are you sure you want to delete this attribute?
                                This action cannot be undone.
                            </p>
                        </div>
                    </>
                }
            />
        </React.Fragment >
    )
}