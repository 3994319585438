import React from 'react'

import { classNames } from '../../utils/Helpers'

export const ToggleSwitch = ({ label, name, disabled, changeHandler, checked }) => {
    return (
        <>
            <label htmlFor={name} className='relative inline-flex cursor-pointer select-none items-center gap-x-2'>
                <span aria-disabled={disabled} className='label flex items-center disabled:text-normal text-sm text-normal'>
                    {label}
                </span>

                <input id={name} disabled={disabled} type="checkbox" name={name} className="sr-only" checked={checked} onChange={changeHandler} />
                <span aria-disabled={disabled} className={classNames(
                    checked ? 'bg-primary' : 'bg-[#CCCCCE]',
                    "slider flex h-[22px] w-[44px] items-center rounded-full p-1 disabled:bg-primary duration-200"
                )}>
                    <span className={classNames(
                        checked ? 'translate-x-5' : '',
                        "dot h-[16px] w-[16px] rounded-full bg-white duration-200"
                    )}>
                    </span>
                </span>
            </label>
        </>
    )
}