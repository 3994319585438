import React, { createContext, useContext, useMemo } from "react"
import type { CSSProperties, PropsWithChildren } from "react"
import type {
    DraggableSyntheticListeners,
    UniqueIdentifier
} from "@dnd-kit/core"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

interface Props {
    id: UniqueIdentifier;
}

interface Context {
    attributes: Record<string, any>;
    listeners: DraggableSyntheticListeners;
    ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
    attributes: {},
    listeners: undefined,
    ref() { }
})

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition
    } = useSortable({ id })

    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef
        }),
        [attributes, listeners, setActivatorNodeRef]
    )

    const style: CSSProperties = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition
    }

    return (
        <SortableItemContext.Provider value={context}>
            <li className="flex rounded-md border border-white hover:border-primary align-middle bg-white box-border list-none shadow-m" ref={setNodeRef} style={style}>
                {children}
            </li>
        </SortableItemContext.Provider>
    )
}

export function DragHandle() {
    const { attributes, listeners, ref } = useContext(SortableItemContext);

    return (
        <div className="flex items-center hover:bg-gray-100 rounded-l-md">
            <button className="flex w-3 p-4 items-center  focus-visible:shadow-md justify-center flex-none touch-none cursor-pointer rounded-md border-none outline-none appearance-none bg-transparent tap-highlight-transparent" {...attributes} {...listeners} ref={ref}>
                <svg className="flex-none m-auto h-full overflow-visible fill-[#919eab]" viewBox="0 0 20 20" width="12">
                    <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                </svg>
            </button>
        </div>

    );
}
