import React from "react"
import ReactDOM from "react-dom"
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react"

import { Thematic } from "../../components/utils/Thematic"

export const ConsentModal = ({ show, showOrHide, actionName, execAction, content }) => {

    return ReactDOM.createPortal(
        <Dialog open={show} onClose={showOrHide} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:max-w-md sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <Thematic>
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="flex h-12 w-12 flex-shrink-0 align-middle items-center justify-center rounded-full bg-slate-100 sm:mx-0 sm:h-12 sm:w-12">
                                        <span className="h-9 w-auto block text-red-600 fa-duotone fa-triangle-exclamation fa-2x"></span>
                                    </div>

                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        {content}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-slate-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <div className="w-full space-y-px">
                                    <div className="flex flex-row-reverse items-center align-middle">
                                        <button type="button" onClick={execAction} className="w-auto min-w-24 justify-center disabled:cursor-not-allowed text-sm rounded-md border border-transparent shadow-sm px-3 py-1.5 bg-red-600 text-white disabled:bg-red-600 hover:bg-red-700 focus:outline-none">
                                            {
                                                actionName
                                            }
                                        </button>

                                        <button type="button" className="text-sm flex-none shadow-none px-3 py-1 bg-inherit text-red-600 hover:text-red-700 hover:underline hover:cursor-pointer mr-2 sm:w-auto sm:text-sm" onClick={showOrHide}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Thematic>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>,
        document.getElementById('root')
    )
}